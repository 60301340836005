import axios from 'axios';
import { GetServerDataProps, GetServerDataReturn, HeadFC, navigate } from 'gatsby';
import React, { useEffect } from 'react';

import Banner from '../images/banner-testitoff.svg';
import KinaliLogo from '../images/logo-kinali.svg';
import TestItOffLogo from '../images/logo-testitoff.svg';
import { Contact } from '../models/contact';

interface ServerData {
    contact: Contact;
    campaignId: string;
    redirect: boolean;
}

const IndexPage: React.FC<{ serverData: ServerData }> = ({ serverData }) => {
    useEffect(() => {
        if (serverData.redirect) {
            navigate('/404');
        }
    }, []);

    const attr = `?utm_source=emailing&utm_medium=email&utm_campaign=${serverData.campaignId}&utm_id=${serverData.campaignId}&utm_content=${serverData?.contact?.email}`;
    return serverData.redirect ? null : (
        <div className='flex flex-col items-center justify-center'>
            <div className='max-w-[960px] px-5'>
                <main>
                    <img src={KinaliLogo} alt='Kinali Logo' className='w-32 mb-6' />

                    <div className='flex items-center justify-center flex-col mb-6'>
                        <div className='rounded-full shadow-[0px_0px_14px_0px_rgba(0,0,0,0.2)] px-10 py-4 md:py-8 flex items-center justify-center'>
                            <p className='text-[20px] md:text-[35px] font-bold text-center'>
                                {serverData.contact.companyName}
                            </p>
                        </div>
                        <p className='text-[35px] font-bold text-center my-1 md:my-8'>and</p>
                        <div className='rounded-full shadow-[0px_0px_14px_0px_rgba(0,0,0,0.2)] px-24 py-3 md:py-5 flex items-center justify-center'>
                            <img
                                src={TestItOffLogo}
                                alt='Test-it-off Logo'
                                className='w-40 md:w-60'
                            />
                        </div>
                    </div>
                    <h1 className='text-[35px] font-bold text-center mb-16'>might get along.</h1>

                    <img src={Banner} alt='Test-it-off Banner' />
                    <p className='text-xl'>&nbsp;</p>

                    <div>
                        <p className='text-[16px] leading-8'>
                            If you are on this site, then{' '}
                            <strong>
                                PCBA quality testing is an important part of your manufacturing
                                processes
                            </strong>
                            . At KINALI, we know the difficulties that companies of your type face.
                            Insufficient number of inspection staff, existing staff leaving and new
                            staff joining etc. All of this disrupts the established production and
                            inspection process.
                        </p>
                        <p className='text-[16px] leading-8'>&nbsp;</p>

                        <p className='text-[16px] leading-8'>
                            <strong>
                                Automating PCBA quality inspection with a Test-it-off solution
                            </strong>{' '}
                            can eliminate all of these ills with one solution that incorporates
                            collaborative robots into the process.
                        </p>
                        <p className='text-[16px] leading-8'>&nbsp;</p>

                        <p className='text-[16px] leading-8'>
                            We are prepared to deliver a turnkey automation system for new projects
                            as well as integrate testers from existing production.
                        </p>
                        <p className='text-[16px] leading-8'>&nbsp;</p>

                        <p className='text-[16px] leading-8'>
                            The Test-it-off solution can operate ICT/FCT testers from most standard
                            manufacturers on the market. Alternatively, in collaboration with our
                            partner MG Products, we can supply{' '}
                            <strong>
                                testers with automated lid to increase the system performance
                            </strong>
                            . Test-it-off can also integrate{' '}
                            <strong>
                                testers with manually operated lids or custom-made test fixtures
                            </strong>
                            .
                        </p>
                        <p className='text-[16px] leading-8'>&nbsp;</p>

                        <p className='text-[16px] leading-8'>
                            A great advantage is the design of the Test-it-off solution based on
                            AUBO collaborative robots. These are safe even without external fencing
                            (necessary for large industrial robots).{' '}
                            <strong>
                                This significantly reduces the physical space required to house the
                                entire facility
                            </strong>
                            .
                        </p>
                        <p className='text-[16px] leading-8'>&nbsp;</p>

                        <p className='text-[16px] leading-8'>
                            However, these are far from all the positives. You can find out more in
                            our video presentation or on{' '}
                            <a
                                target='_blank'
                                href={`https://www.testitoff.cz/en/possibilities-of-using-the-test-it-off-system/automatic-printed-circuit-board-testing${attr}`}
                                className='underline'
                            >
                                testitoff.cz/en
                            </a>{' '}
                            (see buttons below).
                        </p>
                        <p className='text-[16px] leading-8'>&nbsp;</p>
                    </div>

                    <div className='flex flex-col md:flex-row items-center justify-center text-center font-bold gap-10 mb-24'>
                        <a
                            target='_blank'
                            className='bg-[#2AB4C5] text-white py-5 md:w-2/5 w-11/12 hover:bg-[#5fcfdd]'
                            href='https://www.youtube.com/watch?v=6uktcrJCmc0'
                        >
                            Test-it-off video presentation
                        </a>
                        <a
                            target='_blank'
                            className='bg-[#E8E6E6] text-[#2AB4C5] py-5 md:w-2/5 w-11/12 hover:bg-[#f4f3f3]'
                            href={`https://www.testitoff.cz/en/possibilities-of-using-the-test-it-off-system/automatic-printed-circuit-board-testing${attr}`}
                        >
                            Details at testitoff.cz/en
                        </a>
                    </div>
                </main>

                <footer className='border-t-2 border-[#2AB4C5]'>
                    <p className='text-center text-[14px] leading-5 mt-1 mb-10'>
                        <a
                            target='_blank'
                            href={`https://www.kinali.cz/en${attr}`}
                            className='underline'
                        >
                            KINALI
                        </a>{' '}
                        | Cejl 37/62, Brno - Zábrdovice, 602 00, Czechia | e-mail:{' '}
                        <a href='mailto:info@kinali.cz' className='underline'>
                            info@kinali.cz
                        </a>{' '}
                        | tel.:{' '}
                        <a href='tel:+420511112218' className='underline'>
                            +420 511 112 218
                        </a>
                    </p>
                </footer>
            </div>
        </div>
    );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Test-it-off</title>;

export async function getServerData(data: GetServerDataProps): GetServerDataReturn {
    if (data.query && data.query.c !== undefined) {
        const contactId = data?.query['c'] as string;
        const campaignId = (data?.query['utm_campaign'] as string) ?? 'tio-de-1';
        try {
            const endpoint: string = `http://kinalisoft-marketing-tio-eng-webapi:5209/companies/${contactId}`;
            const response = await axios.get<Contact>(endpoint);
            return {
                status: 200,
                props: {
                    contact: response.data,
                    campaignId: campaignId,
                    redirect: false,
                },
            };
        } catch (error) {}
    }
    return {
        status: 200,
        props: {
            contact: undefined,
            campaignId: undefined,
            redirect: true,
        },
    };
}
